// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-jsx": () => import("./../../../src/pages/en/404.jsx" /* webpackChunkName: "component---src-pages-en-404-jsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-about-jsx": () => import("./../../../src/templates/page-about.jsx" /* webpackChunkName: "component---src-templates-page-about-jsx" */),
  "component---src-templates-page-home-jsx": () => import("./../../../src/templates/page-home.jsx" /* webpackChunkName: "component---src-templates-page-home-jsx" */),
  "component---src-templates-page-marques-jsx": () => import("./../../../src/templates/page-marques.jsx" /* webpackChunkName: "component---src-templates-page-marques-jsx" */),
  "component---src-templates-page-presentation-jsx": () => import("./../../../src/templates/page-presentation.jsx" /* webpackChunkName: "component---src-templates-page-presentation-jsx" */)
}

