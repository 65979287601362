import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import PubSub from "pubsub-js"
import { _localizedSlug } from "../core/utils"

const MenuItem = ({ input }) => {
  const [current, setCurrent] = useState()

  const { label, url } = input

  return (
    <div className="menu-item btn outline">
      {url.document ? (
        <Link
          to={`/${_localizedSlug(url.document)}`}
          activeClassName="current-menu-item"
          className={`${current ? "current-menu-item" : ""}`}
        >
          <span>{label}</span>
        </Link>
      ) : (
        <a
          className=""
          href={url.url}
          target="_blank"
          rel="noopener, noreferrer"
        >
          <span>{label}</span>
        </a>
      )}
    </div>
  )
}

export default MenuItem
