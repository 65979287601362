import React, {useContext, useEffect, useState, useRef} from 'react';
import { useStaticQuery, graphql, Link } from "gatsby"
import PubSub from 'pubsub-js';
import { WrapperContext } from "./Layout"
import MenuItem from './MenuItem'
import LocaleSwitcher from './ui/LocaleSwitcher'
import LogoMini from "../images/effective-logo.inline.svg";
import Contact from "./Contact"
import Burger from "./ui/Burger"

const query = graphql`
  {
    menuEn:prismicMenu(lang: {eq: "en-gb"}) {
      ..._menu
    }
    menuFr:prismicMenu(lang: {eq: "fr-fr"}) {
      ..._menu
    }
  }
`

const Menu = () => {
  const _WrapperContext = useContext(WrapperContext)
  const { lang, template } = _WrapperContext
  // let isHome = template === "home"
  const { menuEn, menuFr } = useStaticQuery(query)
  const menu = lang === "fr-fr"
  ? menuFr
  : menuEn

  const [active, setActive] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const menuRef = useRef()

  useEffect(() => {
    const tokenRoute = PubSub.subscribe("ROUTE_UPDATE", (e) => {
      setActive(false)
    })

    const tokenBurger = PubSub.subscribe("BURGER", (e,d) => {
      setActive(d)
    })

    if(window.innerWidth < 768 && 'ontouchstart' in window){
      return
    }
    // if(template )
    const cachedRef = menuRef.current
    // console.log(cachedRef)
    const observer = new IntersectionObserver(
      ([e]) => setIsSticky(e.intersectionRatio < 1),
      {threshold: [1]}
    )
    observer.observe(cachedRef)

    return () => {
      PubSub.unsubscribe(tokenRoute)
      PubSub.unsubscribe(tokenBurger)
      observer.unobserve(cachedRef)
    }
  }, [])

  // const _handleSticky = () => {}
  
  // console.log(isSticky)
  const _getHomeUrl = () => lang === "fr-fr" ? '/' : '/en'

  const _openContact = () => PubSub.publish("CONTACT")

  return (
    <div className={`menu-bar ${(isSticky ? 'is-sticky' : '')}`} ref={menuRef}>
      <div className="container-fluid">
        <div className="row between-xs ">
          <div className="col-md-8 col-xs">
            <div className="x xac">
              <div className="mini-logo">
                <Link to={_getHomeUrl()}><LogoMini /></Link>
              </div>
              <nav className={`menu ${active ? 'is-active' : ''}`}>
                <ul className="x ">
                  {menu.data.menu_items.map((item, i) => (
                    <li key={i}>
                      <MenuItem input={item} />
                    </li>
                  ))}
                  <li className="xs-only">
                    <div className="menu-item btn outline ">
                      <div className="button curp" onClick={_openContact}><span>Contact</span></div>
                    </div>
                  </li>
                </ul>
                {/* <div className="xs-only">
                  <Contact />
                </div> */}
              </nav>
              
            </div>
          </div>
    
          <div className="col-xs text-right">
            <div className="x end-xs">
              <div className="menu-item btn outline hidden-xs">
                <div className="button curp" onClick={_openContact}><span>Contact</span></div>
              </div>
              <LocaleSwitcher />
              <Burger />
            </div>
            
          </div>
        </div>
        
        {/* <div className="hidden-xs">
          <Contact />
        </div> */}
        <Contact />
      </div>
    </div>
  );
};

export default Menu;