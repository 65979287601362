import React, {useContext} from "react"
import Img from "gatsby-image"
import {RichText} from 'prismic-reactjs';
import { WrapperContext } from "./Layout"

const Footer = () => {
  const _WrapperContext = useContext(WrapperContext)
  const { global } = _WrapperContext
  // console.log(global.data)
  return (
    <footer>
      <div className="mixtape mb1">
        <a href={global.data.mix_url.url} target="_blank" rel="noopener">
          <Img {...global.data.mix_image} />
        </a>
      </div>
      {/* <div className="texte fS strong">{RichText.render(global.data.credits.raw)}</div> */}
      <div className="texte fS strong" dangerouslySetInnerHTML={{__html: global.data.credits.html}}></div>

    </footer>
  )
}

export default Footer
