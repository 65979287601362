import React, { useEffect, useState, useContext, useRef } from 'react';
import { RichText } from 'prismic-reactjs';
import PubSub from 'pubsub-js';
import { WrapperContext } from "./Layout"
import { _getLocalizedText } from '../core/utils'

const Contact = () => {
  // console.log(input)
  const _WrapperContext = useContext(WrapperContext)
  const { global } = _WrapperContext

  const [active, setActive] = useState(false);
  const [isMobile, setIsMobile] = useState(undefined)
  const contactRef = useRef()
  // const [top, setTop] = useState(0);

  useEffect(() => {
    // alert(window.innerWidth)
    if(window.innerWidth < 1080 && 'ontouchstart' in window){
      setIsMobile(true)
      // setMobileContactPosition()
    }
  }, [])

  const setMobileContactPosition = () => {
    const menu = document.querySelector("nav.menu")
    const clone = contactRef.current.cloneNode(true);
    menu.appendChild(clone)
    // contactRef.current
    console.log(menu)
  }

  useEffect(() => {
    const tokenRoute = PubSub.subscribe("ROUTE_UPDATE", (e) => {
      // console.log(e)
      setActive(false)
      // detectBtn()
    })

    const tokenContact = PubSub.subscribe("CONTACT", (e) => {
      // console.log(e)
      setActive(!active)
    })

    window.addEventListener('keydown', _onKey)

    if(active){
      // _updatePositionTop()
      document.querySelector(".menu-bar").scrollIntoView({
        behavior: 'smooth'
      })
      document.body.classList.add("no-scroll")
    }else{
      document.body.classList.remove("no-scroll")
    }

    return () => {
      PubSub.unsubscribe(tokenRoute)
      PubSub.unsubscribe(tokenContact)
      window.removeEventListener('keydown', _onKey)
      
    }
  }, [active])

  const _onKey = (e) => {
    if(e.key === "Escape") setActive(false)
  }

  return (
    <div className={`contact modal w100 ${active ? 'is-active' : ''}`} ref={contactRef}>
      <div className="modal-content ">
      < div className="texte fXL">{RichText.render(global.data.contact.raw)}</div>
      </div>
      <div className="modal-close-container fXL strong" onClick={() => setActive(false)}>
        {/* <div className="modal-close" >
          <i></i>
        </div> */}
        {_getLocalizedText("close")}
      </div>
      
    </div>
  );
};

export default Contact;