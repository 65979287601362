import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import { WrapperContext } from "./Layout"
// import Burger from "./UI/Burger"
import PubSub from "pubsub-js"
// import {RichText} from 'prismic-reactjs';
import Logo from "../images/effective-logo.inline.svg"

const Header = () => {
  const _WrapperContext = useContext(WrapperContext)
  const { global, lang } = _WrapperContext
  // console.log(global.data.description_courte)
  // const initialDesc = global.data.description_courte
  const [desc, setDesc] = useState(global.data.description_courte)
  const [isSlide, setIsSlide] = useState(false)

  useEffect(() => {
    const tokenRoute = PubSub.subscribe("ROUTE_UPDATE", (e, { slideDesc }) => {
      setDesc(global.data.description_courte)
    })

    const tokenSlider = PubSub.subscribe(
      "SLIDER_CHANGE",
      (e, { slideDesc }) => {
        // console.log(slideDesc)
        if (slideDesc) {
          setDesc(slideDesc)
          setIsSlide(true)
        } else {
          setDesc(global.data.description_courte)
          setIsSlide(false)
        }
      }
    )

    return () => {
      PubSub.unsubscribe(tokenRoute)
      PubSub.unsubscribe(tokenSlider)
    }
  }, [global])

  useEffect(() => {
    _onResize()
    setTimeout(() => {
      _onResize()
    }, 250)
    document.addEventListener("resize", _onResize)
    return () => document.removeEventListener("resize", _onResize)
  }, [])

  const _onResize = () => {
    const headerHeight = Math.round(
      document.querySelector("header").clientHeight
    )
    // console.log(document.querySelector("header"))
    // console.log("headerHeight", headerHeight)
    document.documentElement.style.setProperty(
      "--headerHeight",
      headerHeight + "px"
    )
    document.documentElement.style.setProperty(
      "--innerHeight",
      window.innerHeight + "px"
    )
  }

  const _getHomeUrl = () => (lang === "fr-fr" ? "/" : "/en")

  return (
    <header>
      <div className="header-jumbo">
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <Link to={_getHomeUrl()} className="logo-jumbo" title="logo">
              <Logo />
            </Link>
          </div>
          <div className="col-xs">
            <div
              className={`h100 description outline fLAlt strong rouge ${
                isSlide ? "is-slide" : ""
              }`}
            >
              <div className="inner">{desc}</div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
