import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./Header"
import Menu from "./Menu"
import Footer from "./Footer"
import VideoPlayer from "./ui/VideoPlayer"


const WrapperContext = React.createContext()

const query = graphql`
  query {
    globalFr: prismicGlobal(lang: {eq: "fr-fr"}) {
      ..._prismicGlobal
    }
    globalEn: prismicGlobal(lang: {eq: "en-gb"}) {
      ..._prismicGlobal
    }
  }
`

const Layout = ({ children, pageContext: { template, lang } }) => {
  const { globalFr, globalEn } = useStaticQuery(query)
  // console.log("lang", lang)
  // console.log("template", template)
  const global = lang === 'fr-fr'
  ? globalFr
  : globalEn
  
  // console.log(global.data)
  // const emptyObj = {}
  
  return (
    <WrapperContext.Provider value={{ global, lang:lang, template }}>
      <div id="page">
        {template === "home" && <VideoPlayer input={global.data.video_url.url}/>}
        {(template === "home" || template === "presentation") && <Header />}
        
        <Menu />
        <main>{children}</main>
        {template !== "presentation" && 
          <Footer />
        }
        
      </div>
    </WrapperContext.Provider>
  )
}

export { WrapperContext, Layout }

