import React, { useContext } from "react"
import { Link } from "gatsby"
import { WrapperContext } from "../Layout"
// import PubSub from "pubsub-js"

// import { ContextHoc } from "../../context/ContextHoc"
const locales = require("../../../config/i18n")

const LocaleSwitcher = () => {

	const _WrapperContext = useContext(WrapperContext)
	const { lang } = _WrapperContext || "fr-FR"
	// console.log(lang)
	const renderLanguages = () => {
		// const { nav } = this.state
    // const { locale } = this.props
    // const otherLang = locales.filter(el => {
    //   return el.locale !== lang
    // })
    // console.log(locales)
    // console.log(otherLang)
		const languages = Object.values(locales).map((li, key) => {
      const isActiveClass = li.locale === lang ? 'is-active' : ''
      // console.log(isActiveClass)
			// const _className = li.locale === lang ? "active" : ""
			const path = li.default ? `/` : `/${li.path}`
			return (
				<li key={key} className="btn outline">
					<Link to={path} className={`${isActiveClass} ttu`} >
						<span>{li.label}</span>
					</Link>
				</li>
			)
		})
		return languages
	}

	return(
		<ul className="locale-switcher x end-xs  ">
			{renderLanguages()}
		</ul>
	)
}

export default LocaleSwitcher
