import React, { useContext } from 'react';
import PrismicDOM from 'prismic-dom';
import { WrapperContext } from "../components/Layout"
const Elements = PrismicDOM.RichText.Elements;

const locales = require("../../config/i18n")
// export function fileNameByUrl(url) {
// 	const decoded = decodeURIComponent(url)
// 	return decoded.substring(decoded.lastIndexOf("/") + 1)
// }
export const _getLocalizedText = (text) => {
  const _WrapperContext = useContext(WrapperContext)
  const { lang } = _WrapperContext
  // console.log("locale",locale, text)
  return locales[lang] && locales[lang][text] 
  ? locales[lang][text]
  : text
}



export const _getInDateRange = (du, au) => {
  const now = new Date().getTime()
  const _du = new Date(du).getTime()

  if(du && au){
    const _au = new Date(au).getTime()
    if(now <= _du)return true
    if(now <= _du && now <= _au)return true
    if(now >= _du && now <= _au)return true
  }else{

    return now <= _du
  }
}



export const isInViewport = (elem) => {
  const bounding = elem.getBoundingClientRect();
  return (
      bounding.top >= 0 &&
      bounding.left >= 0 &&
      bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};



// export const localizedHomeSlug = () => {

// }

export const _localizedSlug = (node) => {
  // console.log(node)
  if(!node.uid)return '/'
  const link = linkResolver(node)
  // console.log(node.lang)
  // console.log(link)
  return locales[node.lang].default
    ? `${link}`
    : `${locales[node.lang].path}/${link}`   
  
}

// export const _localizedSlugWithPrefix = (node) => {

// }

export const linkResolver = (doc) => {
  // console.log(doc.type, doc.uid)
  if(doc.type === "page")return `${doc.uid}`;
  if(doc.type === "presentation")return `${doc.uid}`;
 
  return '/';
}

export const _htmlSerializer = (type, element, content, children) => {
  console.log(type)
  console.log(element)
  console.log(content)
  console.log(children)
  switch(type) {
    case Elements.paragraph: return `<p>${children.join('')}</p>`;
    default: return null;
  }
}